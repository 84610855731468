import { FormattedMessage, useIntl } from 'react-intl';
import StepLayout from 'src/components/StepLayout';
import {
  File as CloudFile,
  Connection,
  ConnectionId,
  MongoDBConnection,
  IntegrationMongoDB,
  IntegrationType,
} from 'types';
import { SourceStep, SourceStepSummary } from '../common/SourceStep';
import { useState } from 'react';
import ConnectionDetailsStep from './steps/ConnectionDetailsStep';
import {
  CreateConnectionForm,
  CreateIntegrationMongoDBBody,
  MONGODB_SOURCE_FORM_DEFAULT_VALUES,
} from './steps/forms';
import CollectionDetailsStep from './steps/CollectionDetailsStep';
import TableStep from './steps/TableStep';
import CollectionDetailsStepSummary from './steps/CollectionDetailsStepSummary';
import { useNavigate, useParams } from 'react-router-dom';
import ConnectionDetailsStepSummary from './steps/ConnectionDetailsStepSummary';
import { apiPatch, apiPost, apiPut } from 'src/api';
import useMessage from 'src/hooks/useMessage';
import { clusterImport } from 'src/constants/paths';
import {
  useGetClustersIdConnectionsId,
  useGetClustersIdIntegrations,
} from 'src/swrHooks';
import { AUTH_TYPES, FIELD_NAMES } from '../constants';
import IntegrationSettingsStep from './steps/IntegrationSettingsStep';
import TableStepSummary from './steps/TableStepSummary';
import { INTEGRATION_TYPES } from 'src/constants/integrations';
import { CONNECTION_TYPES } from 'src/constants/connection';
import { useAnalytics } from 'src/hooks';
import { USER_TRACKING_EVENTS } from 'src/constants/segment';
import { Alert } from 'antd';
import LoadingContainer from 'src/components/LoadingContainer';

export type MongoDBSourceProps = {
  integration?: IntegrationMongoDB;
  sourceType: IntegrationType;
  setSourceType: (type: IntegrationType | null) => void;
  onUpdate: () => void;
};

const mergeForm = (
  form: CreateIntegrationMongoDBBody,
  result: CreateIntegrationMongoDBBody,
) => {
  return {
    ...form,
    ...result,
    destination: {
      ...form.destination,
      ...result.destination,
    },
    mongodb: {
      ...form.mongodb,
      ...result.mongodb,
    },
  } satisfies CreateIntegrationMongoDBBody;
};

function MongoDBSource({
  integration,
  sourceType,
  setSourceType,
  onUpdate,
}: MongoDBSourceProps) {
  const { trackEvent } = useAnalytics();
  const { organizationId, clusterId, projectId } = useParams();
  const { formatMessage } = useIntl();
  const { showSuccessMessage, showErrorMessage, showLoadingMessage } = useMessage();
  const navigate = useNavigate();

  // states
  const [attachedCertificateFile, setAttachedCertificateFile] = useState<
    CloudFile | File | null
  >(null);
  const [stepIndex, setStepIndex] = useState(integration ? 4 : 1);
  const [form, setForm] = useState<CreateIntegrationMongoDBBody>(
    integration || MONGODB_SOURCE_FORM_DEFAULT_VALUES,
  );
  const [connectionDetails, setConnectionDetails] = useState<
    CreateConnectionForm | Connection
  >();
  const [selectedConnection, setSelectedConnection] = useState<
    'new' | ConnectionId | undefined
  >(integration ? integration.mongodb.connection_id : undefined);
  const [createdConnectionId, setCreatedConnectionId] = useState<ConnectionId>();

  // swr
  const { mutate: mutateIntegrations } = useGetClustersIdIntegrations(clusterId!);
  const {
    data: resumingIntegrationConnection,
    isLoading: isLoadingResumingIntegrationConnection,
  } = useGetClustersIdConnectionsId(
    organizationId!,
    integration ? integration.mongodb.connection_id : null,
  );

  const onNextStep = () => {
    setStepIndex(stepIndex => stepIndex + 1);
  };

  const handleSetConnection = (
    connectionDetails: CreateConnectionForm | Connection,
  ) => {
    setConnectionDetails(connectionDetails);
    setForm({
      ...form,
      [FIELD_NAMES.MONGODB_NAMESPACE]: {
        ...form[FIELD_NAMES.MONGODB_NAMESPACE],
        [FIELD_NAMES.MONGODB_DATABASE_NAME]: connectionDetails.default_db_name,
      },
    });
    onNextStep();
  };

  const handleCreateConnection = async () => {
    if (createdConnectionId) {
      return createdConnectionId;
    } else if ('id' in connectionDetails!) {
      return connectionDetails.id;
    } else {
      const connectionType = connectionDetails!.auth;

      const { success, data } = await apiPost<MongoDBConnection>(
        `/api/v2/organizations/${organizationId}/connections/`,
        {
          type: CONNECTION_TYPES.MONGODB,
          auth: connectionType,
          name: connectionDetails!.name,
          connection_string: connectionDetails!.connection_string,
          default_db_name: connectionDetails!.default_db_name,
          ...(connectionDetails!.auth === AUTH_TYPES.SCRAM
            ? {
                // SCRAM BODY
                username: connectionDetails!.username,
                password: connectionDetails!.password,
              }
            : {
                // X509 BODY
                certificate: connectionDetails!.certificate,
              }),
        },
        undefined,
        false,
      );

      if (!success) {
        // show error message
        showErrorMessage(
          formatMessage({
            id: 'cluster.clusterImportIntegration.errorCreatingConnection',
          }),
        );
        return null;
      }
      const connectionId = data!.id;
      setCreatedConnectionId(connectionId);
      return connectionId;
    }
  };

  const onFinishCreate = async (form: CreateIntegrationMongoDBBody) => {
    showLoadingMessage(
      formatMessage({
        id: 'cluster.clusterImportIntegration.creatingIntegration',
      }),
    );
    trackEvent(USER_TRACKING_EVENTS.CLICKED_CREATE_INTEGRATION);

    const connectionId = await handleCreateConnection();
    if (!connectionId) {
      return;
    }

    const { success } = await apiPost<IntegrationMongoDB>(
      `/api/v2/clusters/${clusterId}/import-jobs/`,
      {
        format: INTEGRATION_TYPES.MONGODB,
        name: form.name,
        compression: null,
        type: form.type,
        ingestion_type: form.ingestion_type,
        destination: form.destination,
        mongodb: { ...form.mongodb, connection_id: connectionId },
      },
      undefined,
      false,
    );

    if (success) {
      mutateIntegrations();

      showSuccessMessage(
        formatMessage({
          id: 'cluster.clusterImportIntegration.integrationCreated',
        }),
      );

      // go back to job list
      navigate(
        clusterImport.build({
          clusterId,
          organizationId,
          projectId,
        }),
      );
    } else {
      // show error message
      showErrorMessage(
        formatMessage({
          id: 'cluster.clusterImportIntegration.errorCreatingIntegration',
        }),
      );
    }
  };

  const onFinishResume = async (form: CreateIntegrationMongoDBBody) => {
    showLoadingMessage(
      formatMessage({
        id: 'cluster.clusterImportIntegration.resumingIntegration',
      }),
    );
    trackEvent(USER_TRACKING_EVENTS.CLICKED_RESUME_INTEGRATION);

    const connectionId = await handleCreateConnection();
    if (!connectionId) {
      return;
    }

    // update integration
    const { success: successUpdatingIntegration } =
      await apiPatch<IntegrationMongoDB>(
        `/api/v2/clusters/${clusterId}/import-jobs/${integration!.id}/`,
        {
          format: INTEGRATION_TYPES.MONGODB,
          name: form.name,
          compression: null,
          type: form.type,
          ingestion_type: form.ingestion_type,
          destination: form.destination,
          mongodb: { ...form.mongodb, connection_id: connectionId },
        },
        undefined,
        false,
      );

    if (!successUpdatingIntegration) {
      // show error message
      showErrorMessage(
        formatMessage({
          id: 'cluster.clusterImportIntegration.errorUpdatingIntegration',
        }),
      );
      return;
    }
    onUpdate();

    // resume integration
    const { success: successResumingIntegration } = await apiPut(
      `/api/v2/clusters/${clusterId}/import-jobs/${integration!.id}/resume/`,
      null,
      undefined,
      false,
    );

    if (successResumingIntegration) {
      mutateIntegrations();

      showSuccessMessage(
        formatMessage({
          id: 'cluster.clusterImportIntegration.integrationResumed',
        }),
      );

      // go back to job list
      navigate(
        clusterImport.build({
          clusterId,
          organizationId,
          projectId,
        }),
      );
    } else {
      // show error message
      showErrorMessage(
        formatMessage({
          id: 'cluster.clusterImportIntegration.errorResumingIntegration',
        }),
      );
    }
  };

  const isResuming = typeof integration !== 'undefined';

  return (
    <LoadingContainer
      loading={isLoadingResumingIntegrationConnection}
      render={() => {
        if (resumingIntegrationConnection && !connectionDetails) {
          setConnectionDetails(resumingIntegrationConnection);
        }

        return (
          <div>
            <StepLayout
              stepIndex={stepIndex}
              setStepIndex={index => {
                if (index === 0) {
                  // if we are going back to source step
                  // then reset the source type
                  setSourceType(null);
                  setStepIndex(1);
                } else {
                  setStepIndex(index);
                }
              }}
              steps={[
                {
                  title: (
                    <FormattedMessage id="cluster.clusterImportIntegration.sourceStep.title" />
                  ),
                  renderContent: () => (
                    <SourceStep
                      sourceType={sourceType}
                      onChangeSourceType={setSourceType}
                    />
                  ),
                  summaryContent: () => (
                    <SourceStepSummary sourceType={sourceType} />
                  ),
                },
                {
                  title: (
                    <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.title" />
                  ),
                  renderContent: () => (
                    <ConnectionDetailsStep
                      organizationId={organizationId!}
                      clusterId={clusterId!}
                      selectedConnection={selectedConnection}
                      connectionDetails={connectionDetails}
                      attachedCertificateFile={attachedCertificateFile}
                      setSelectedConnection={setSelectedConnection}
                      setAttachedCertificateFile={setAttachedCertificateFile}
                      onNext={handleSetConnection}
                    />
                  ),
                  summaryContent: () => (
                    <ConnectionDetailsStepSummary
                      attachedCertificateFile={attachedCertificateFile}
                      values={
                        connectionDetails
                          ? connectionDetails
                          : resumingIntegrationConnection!
                      }
                    />
                  ),
                },
                {
                  title: (
                    <FormattedMessage id="cluster.clusterImportIntegration.collectionDetailsStep.title" />
                  ),
                  renderContent: () => (
                    <CollectionDetailsStep
                      defaultValues={form}
                      onNext={(result: CreateIntegrationMongoDBBody) => {
                        setForm(mergeForm(form, result));
                        onNextStep();
                      }}
                    />
                  ),
                  summaryContent: () => (
                    <CollectionDetailsStepSummary values={form} />
                  ),
                },
                {
                  title: (
                    <FormattedMessage id="cluster.clusterImportIntegration.tableStep.title" />
                  ),
                  renderContent: () => (
                    <TableStep
                      defaultValues={form}
                      onNext={(result: CreateIntegrationMongoDBBody) => {
                        setForm(mergeForm(form, result));
                        onNextStep();
                      }}
                    />
                  ),
                  summaryContent: () => <TableStepSummary values={form} />,
                },
                {
                  title: (
                    <FormattedMessage id="cluster.clusterImportIntegration.integrationSettingsStep.title" />
                  ),
                  renderContent: () => (
                    <IntegrationSettingsStep
                      isResuming={isResuming}
                      defaultValues={form}
                      onNext={(result: CreateIntegrationMongoDBBody) => {
                        const finalForm = mergeForm(form, result);
                        setForm(finalForm);

                        if (isResuming) {
                          onFinishResume(finalForm);
                        } else {
                          onFinishCreate(finalForm);
                        }
                      }}
                    />
                  ),
                },
              ]}
            />
            {isResuming && (
              <div className="ml-12 flex">
                <Alert
                  message={formatMessage({
                    id: 'cluster.clusterImportIntegration.resumeWarningText',
                  })}
                  type="warning"
                />
              </div>
            )}
          </div>
        );
      }}
    />
  );
}

export default MongoDBSource;
